import React from "react";
import { withTranslation } from "react-i18next";
import Popup from "./atoms/Popup";
// import copy from "../common/data/copy.json";
// eslint-disable-next-line
const Infopopup = ({ t, i18n, isOpen, onClose, language, styles }) => (
  <Popup
    title={t("navigation_header")}
    content={t("navigation_intro")}
    onClose={onClose}
    isOpen={isOpen}
    styles={styles}
  />
);

export default withTranslation()(Infopopup);
