import "../scss/main.scss";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";

class App extends React.Component {
  render() {
    return (
      <>
        <Router>
          <Routes>
            <Route index element={<Layout />} />
          </Routes>
        </Router>
      </>
    );
  }
}

export default App;
