import React from "react";
import ReactTooltip from "react-tooltip";
import SitesIcon from "../atoms/SitesIcon";
import CoverIcon from "../atoms/CoverIcon";
import InfoIcon from "../atoms/InfoIcon";
import { useTranslation } from "react-i18next";

function BottomActions(props) {
  // eslint-disable-next-line no-unused-vars
  const { i18n, t } = useTranslation("translation");

  function renderToggles() {
    return (
      <>
        <div className="bottom-action-block-icon">
          <a
            href="https://www.myanmarwitness.org/"
            target="_blank"
            rel="noreferrer"
          >
            {/* <ReactSVG src="mw_logo.svg" className="icon" /> */}
            <img
              src="sidebaricon.png"
              className="icon"
              alt="Myanmar Witness"
              width="100px"
            />
          </a>
        </div>

        <div className="bottom-action-block">
          {props.features.USE_SITES ? (
            <SitesIcon
              isActive={props.sites.enabled}
              onClickHandler={props.sites.toggle}
            />
          ) : null}
        </div>

        <div className="botttom-action-block">
          <ReactTooltip id="info" place="top" effect="solid">
            {t("icon_info")}
          </ReactTooltip>
          <InfoIcon
            isActive={props.info.enabled}
            onClickHandler={props.info.toggle}
          />
        </div>

        <div className="botttom-action-block">
          <ReactTooltip id="home" place="top" effect="solid">
            {t("icon_home")}
          </ReactTooltip>
          {props.features.USE_COVER ? (
            <CoverIcon onClickHandler={props.cover.toggle} />
          ) : null}
        </div>

        <div className="botttom-action-block">
          <ReactTooltip id="question_mark" place="top" effect="solid">
            {i18n.language === "en" ? (
              <span>Request additional information from Myanmar Witness</span>
            ) : (
              <span>Myanmar Witness ထံမှ အချက်အလက် ထပ်မံတောင်းခံရန်</span>
            )}
          </ReactTooltip>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScoUJbq5imZTwfuEBUKB7giMdHCPoE-FroMFdLsxPlfEaYcGw/viewform?usp=sf_link" target="_blank" rel="noreferrer">
            <button className="action-button" data-tip data-for="question_mark">
              <i className="material-icons">question_mark</i>
            </button>
          </a>
        </div>
      </>
    );
  }

  return <div className="bottom-actions">{renderToggles()}</div>;
}

export default BottomActions;