import React from "react";
import Checkbox from "../atoms/Checkbox";
import marked from "marked";
import {
  aggregateFilterPaths,
  getFilterIdxFromColorSet,
  getPathLeaf,
} from "../../common/utilities";

/** recursively get an array of node keys to toggle */
function getFiltersToToggle(filter, activeFilters) {
  const [key, children] = filter;
  // base case: no children to recurse through
  if (children === {}) return [key];
  const turningOff = activeFilters.includes(key);
  const childKeys = Object.entries(children)
    .flatMap((filter) => getFiltersToToggle(filter, activeFilters))
    .filter((child) => activeFilters.includes(child) === turningOff);
  childKeys.push(key);
  return childKeys;
}

function FilterListPanel({
  filters,
  activeFilters,
  onSelectFilter,
  language,
  coloringSet,
  filterColors,
  title,
  description,
}) {
  function createNodeComponent(filter, depth) {
    const [key, children] = filter;
    const pathLeaf = getPathLeaf(key);
    const matchingKeys = getFiltersToToggle(filter, activeFilters);
    const idxFromColorSet = getFilterIdxFromColorSet(key, coloringSet);
    const assignedColor =
      idxFromColorSet !== -1 && activeFilters.includes(key)
        ? filterColors[idxFromColorSet]
        : "";
    function insideAssignedColor(pk) {
      const insideIdx = getFilterIdxFromColorSet(pk, coloringSet);
      const insideasgcolor =
        insideIdx !== -1 && activeFilters.includes(pk)
          ? filterColors[insideIdx]
          : "";
      return insideasgcolor;
    }

    const styles = {
      color: assignedColor,
      marginLeft: `${depth * 10}px`,
    };

    console.log("activeFilters", activeFilters);

    // console.log("coloringSet", coloringSet)
    // console.log("filterColors", filterColors)
    // console.log("assignedColor", assignedColor)
    return (
      <li
        key={pathLeaf.replace(/ /g, "_")}
        className="filter-filter"
        style={{ ...styles }}
      >
        <Checkbox
          label={pathLeaf}
          isActive={activeFilters.includes(key)}
          onClickCheckbox={() => onSelectFilter(key, matchingKeys)}
          color={assignedColor}
        />
        <div
          style={{
            padding: "0.25rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            {Object.keys(children).length > 0
              ? Object.entries(children).map((filter) => {
                  // console.log("depth", depth, "\n");
                  // console.log("filter", filter, "\n");
                  // console.log("filter[0]", filter[0]);
                  if (depth > 0) {
                    let inasgcolor = insideAssignedColor(filter[0]);
                    const defstyles = {
                      color: "white",
                      padding: "0.15rem",
                      cursor: "pointer",
                      fontWeight: "bold",
                    };
                    const actstyles = {
                      color: `${
                        inasgcolor && inasgcolor !== "" ? inasgcolor : "white"
                      }`,
                      padding: "0.15rem",
                      cursor: "pointer",
                      fontSize: "1em",
                      textTransform: "uppercase",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    };
                    return (
                      <div
                        style={{
                          margin: "0.15rem",
                        }}
                      >
                        <span
                          style={
                            activeFilters.includes(filter[0])
                              ? { ...actstyles }
                              : { ...defstyles }
                          }
                          onClick={() => onSelectFilter(filter[0], filter)}
                          key={pathLeaf.replace(/ /g, "_")}
                        >
                          {filter[0].split("/")[1]}
                        </span>
                      </div>
                    );
                  }
                  return createNodeComponent(filter, depth + 1);
                })
              : null}
          </div>
        </div>

        {/* {Object.keys(children).length > 0
          ? Object.entries(children).map((filter) =>
            createNodeComponent(filter, depth + 1)
          )
          : null} */}
      </li>
    );
  }

  function renderTree(filters) {
    const aggregatedFilterPaths = aggregateFilterPaths(filters);
    return (
      <div>
        {Object.entries(aggregatedFilterPaths).map((filter) =>
          createNodeComponent(filter, 1)
        )}
      </div>
    );
  }

  return (
    <div className="react-innertabpanel">
      <h2>{title}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: marked(description),
        }}
      />
      {renderTree(filters)}
    </div>
  );
}

export default FilterListPanel;
