import React from "react";
import moment from "moment";
import "moment/locale/my";
import { useTranslation } from "react-i18next";

const TimelineHeader = ({ title, from, to, onClick, hideInfo, locations }) => {
  const { t, i18n } = useTranslation("translation");
  const clng = i18n.language === "mm" ? "my" : "en";
  const d0 = from && moment(from).locale(clng).format("D MMMM YYYY");
  const d1 = to && moment(to).locale(clng).format("D MMMM YYYY");

  function translateNumber(number) {
    // new Intl.NumberFormat("mm", { numberingSystem: "mymr", useGrouping: false}).format(locations)
    const l = i18n.language === "mm" ? "my-MM-u-nu-mymr" : "en-US";
    const options = {
      numberingSystem: i18n.language === "mm" ? "mymr" : "latn",
      useGrouping: false,
    };
    return new Intl.NumberFormat(l, options).format(number);
  }

  return (
    <div className="timeline-header">
      <div className="timeline-toggle" onClick={() => onClick()}>
        <p>
          <i className="arrow-down" />
        </p>
      </div>
      <div className={`timeline-info ${hideInfo ? "hidden" : ""}`}>
        <p>{title}</p>
        <p>
          {d0} - {d1}
        </p>
        <p>
          {t("cardstack_header")}: {translateNumber(locations)}{" "}
        </p>
      </div>
      {/* <div style={{ marginLeft: "1rem", paddingLeft: "0.5rem" }}>
        <p>selected events: {locations} </p>
      </div> */}
    </div>
  );
};

export default TimelineHeader;
